<template>
  <div class="sorter">
    <div class="sorter__group">
      <span class="me-10 text-anthracite">Sorteren op:</span>

      <div class="form-select">
        <!-- Comments are from Steph -->
        <select class="form-control" @change="handleChange">
          <!-- Relevantie, is default so empty value -->
          <option value="" selected>
            Relevantie
          </option>
          <!-- Laagste vanaf prijs -->
          <option value="minimum_price">
            Laagste prijs
          </option>
          <!-- Same as recommended until enough products are sold -->
          <option value="recommended">
            Beste verkocht
          </option>
          <!-- Levertijd bij kaal onbedrukt product -->
          <option value="delivery_days">
            Snelste levertijd
          </option>
          <!-- Laagste minimale bestelhoeveelheid -->
          <option value="minimum_order_quantity">
            Kleinste oplage
          </option>
          <!-- De producten die featured en/of uitgelicht zijn in de categorie -->
          <option value="recommended">
            Aanbevolen door ons
          </option>
          <!-- 100 x Eindprijs = getoonde prijs in overzicht / sortering -->
          <option value="price_100_units">
            Prijs bij 100 stuks
          </option>
        </select>
      </div>
    </div>

    <span class="sorter__counter">
      Artikel {{ page.size * (page.current - 1) + 1 }} - {{ page.size * (page.current - 1) + resultsOnPage }} van {{ amountOfResults }}
    </span>
  </div>
</template>

<script>
import select from '../../../forms/select';

export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
    results: {
      type: Array,
      required: true,
    },
    amountOfResults: {
      type: Number,
      required: true,
    },
  },
  emits: ['sort-changed'],
  computed: {
    resultsOnPage() {
      return this.results?.length;
    },
  },
  created() {
    select();
  },
  methods: {
    handleChange({ srcElement: { value } }) {
      this.$emit('sort-changed', value);
    },
  },
};
</script>
