<template>
  <div class="card card--product">
    <a :href="productUrl">
      <div v-if="hasDiscount" class="badge badge--discount">
        <span
          class="badge--discount__bg"
          v-bind:style="labelBackgroundColorStyle"
        ></span>

        <span class="badge--discount__text" v-bind:style="labelTextColorStyle">
          Actie!
        </span>
      </div>

      <div v-if="hasLabel" class="badge badge--discount">
        <span
          class="badge--discount__bg"
          v-bind:style="labelBackgroundColorStyle"
        ></span>

        <span class="badge--discount__text" v-bind:style="labelTextColorStyle">
          {{ labelContent }}
        </span>
      </div>

      <div v-if="productImageUrl" class="card--product__img">
        <figure>
          <img :src="productImageUrl" loading="lazy" :alt="productName" />
        </figure>
      </div>
    </a>

    <div class="thumbnail-slider">
      <ul class="thumbnail-slider__track">
        <li v-for="variation in variations" :key="variation.name">
          <figure>
            <img
              :src="
                variation.thumbnail_webp.replace(
                  '.io/zintuig/',
                  '.io/zintuiglive/'
                )
              "
              loading="lazy"
              :alt="variation.name"
              :title="variation.name"
            />
          </figure>
        </li>
      </ul>

      <div
        v-if="variations && variations.length > 4"
        class="thumbnail-slider__controls"
      >
        <button type="button" class="prev">
          <i class="icon-chevron-left"></i>
        </button>

        <button type="button" class="next">
          <i class="icon-chevron-right"></i>
        </button>
      </div>
    </div>

    <a :href="productUrl" class="card--product__body">
      <div class="card--product__title h5">
        {{ productName }}
      </div>

      <span
        class="hot-item-text"
        v-html="hotItemText"
        v-show="hotItemText"
      ></span>

      <span>{{ sku }}</span>

      <ul class="list-icon" v-if="!hotItemText">
        <li>
          <span
            ><i class="icon-truck-fast-alt"></i
            >{{ deliveryDateNoImprint }}</span
          >
        </li>
        <li>
          <span
            ><i class="icon-boxes"></i>vanaf
            {{ minimumOrderQuantity }} stuks</span
          >
        </li>
      </ul>

      <div class="price">
        <small v-if="hasDiscount" class="price__old"
          >vanaf {{ oldPrice }}</small
        >

        <div :class="currentPriceClass" v-if="hasPrice">
          vanaf
          <span class="price__current__price h3">{{ currentPrice }}</span>
        </div>
        <div :class="currentPriceClass" v-else>Op aanvraag</div>
      </div>
    </a>
  </div>
</template>

<script>
import moment from "moment";

const DATE_FORMAT = "D MMMM";
const SUNDAY = 0; // moment day index
const SATURDAY = 6; // moment day index
const WEEKENDS = [SATURDAY, SUNDAY];

function addBusinessDays(numberOfDays, dateFormat = DATE_FORMAT) {
  const date = moment();

  let count = 0;

  while (count < numberOfDays) {
    date.add(1, "day");

    // Skip weekends
    if (!WEEKENDS.includes(date.day())) {
      count += 1;
    }
  }

  return date.format(dateFormat);
}

export default {
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  computed: {
    product() {
      return this.result.data;
    },
    productName() {
      const {
        name: { raw },
      } = this.product;
      return raw;
    },
    productId() {
      const {
        id: { raw },
      } = this.product;
      return raw;
    },
    sku() {
      return this.product.sku?.raw;
    },
    hotItemText() {
      return this.product.hot_item_text?.raw ?? "";
    },
    minimumOrderQuantity() {
      return this.product.minimum_order_quantity?.raw ?? 1;
    },
    deliveryTimeNoImprint() {
      return this.product.delivery_days?.raw;
    },
    deliveryDateNoImprint() {
      return addBusinessDays(this.product.delivery_days?.raw);
    },
    hasDiscount() {
      if (
        this.product.minimum_discount_price &&
        this.product.minimum_discount_price.raw > 0 &&
        this.product.minimum_discount_price.raw < this.product.minimum_price.raw
      ) {
        return true;
      }

      return false;
    },
    getLabel() {
      try {
        return JSON.parse(this.product.labels.raw[0]);
      } catch (e) {
        return false;
      }
    },
    hasLabel() {
      if (this.hasDiscount) {
        return false;
      }

      if (this.getLabel?.name) {
        return true;
      }

      return false;
    },
    labelBackgroundColorStyle() {
      return {
        "border-block-start": `var(--size-badge-discount) solid ${this.labelBackgroundColor}`,
      };
    },
    labelTextColorStyle() {
      return {
        color: this.labelColor,
      };
    },
    labelContent() {
      if (this.hasLabel && this.getLabel?.content) {
        return this.getLabel?.content;
      }

      return "";
    },
    labelColor() {
      if (this.hasLabel && this.getLabel?.color) {
        return this.getLabel?.color;
      }

      return "#FFFFFF";
    },
    labelBackgroundColor() {
      if (this.hasLabel && this.getLabel?.background_color) {
        return this.getLabel?.background_color;
      }

      return "#FF7043";
    },
    currentPrice() {
      if (this.hasDiscount) {
        return new Intl.NumberFormat("nl-NL", {
          style: "currency",
          currency: "EUR",
        }).format(this.product.minimum_discount_price.raw);
      }

      return new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      }).format(this.product.minimum_price.raw);
    },
    hasPrice() {
      return this.product.minimum_price.raw !== null;
    },
    oldPrice() {
      return new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      }).format(this.product.minimum_price.raw);
    },
    productUrl() {
      return this.product.url?.raw;
    },
    productImageUrl() {
      return this.product.image_url?.raw;
    },
    variations() {
      try {
        return JSON.parse(this.product.variations?.raw);
      } catch (e) {
        return {};
      }
    },
    currentPriceClass() {
      let classString = "price__current";

      if (this.hasDiscount) {
        classString += " text-orange";
      }

      return classString;
    },
  },
};
</script>
