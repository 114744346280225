import { reactive, toRefs } from 'vue';
import * as ElasticAppSearch from '@elastic/app-search-javascript';

const data = reactive({
  clients: {},
});

export function useAppSearch() {
  function createClient(options) {
    const { client: { searchKey, endpointBase, engineName } } = options;
    const client = ElasticAppSearch.createClient({
      searchKey,
      endpointBase,
      engineName,
    });

    data.clients[`${engineName}Client`] = {
      instance: client,
      options,
    };
  }

  return {
    createClient,
    ...toRefs(data),
  };
}
