<template>
  <ul class="pagination">
    <li :class="{ disabled: isInFirstPage }">
      <button type="button" @click="onClickFirstPage(); scrollToTop();">
        <i class="icon-chevron-left"></i>
        <i class="icon-chevron-left"></i>
      </button>
    </li>

    <li :class="{ disabled: isInFirstPage }">
      <button type="button" @click="onClickPreviousPage(); scrollToTop();">
        <i class="icon-chevron-left"></i>
      </button>
    </li>

    <li v-for="page in pages" :key="page" :class="{ disabled: page.isDisabled, active: isPageActive(page.name) }">
      <button type="button" @click="onClickPage(page.name); scrollToTop();">
        {{ page.name }}
      </button>
    </li>

    <li :class="{ disabled: isInLastPage }">
      <button type="button" @click="onClickNextPage(); scrollToTop();">
        <i class="icon-chevron-right"></i>
      </button>
    </li>

    <li :class="{ disabled: isInLastPage }">
      <button type="button" @click="onClickLastPage(); scrollToTop();">
        <i class="icon-chevron-right"></i>
        <i class="icon-chevron-right"></i>
      </button>
    </li>
  </ul>
</template>

<script>
// TODO: When the user clicks on a new page, don't forget to set the page param
export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  emits: ['page-set'],
  data() {
    return {
      maxVisibleButtons: 3,
    };
  },
  computed: {
    currentPage() {
      const { current } = this.page;
      return current;
    },
    amountOfPages() {
      const { total_pages: totalPages } = this.page;
      return totalPages;
    },
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }

      if (this.currentPage === this.amountOfPages) {
        return this.amountOfPages - this.maxVisibleButtons + 1;
      }

      return this.currentPage - 1;
    },
    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.amountOfPages);
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.amountOfPages;
    },
  },
  created() {
    const page = new URLSearchParams(window.location.search).get('page');
    if (page) {
      this.$emit('page-set', JSON.parse(page));
    }
  },
  methods: {
    scrollToTop() {
      const section = document.querySelector('.section--search');
      const navbarHeight = document.querySelector('.navbar-main').offsetHeight;

      window.scrollTo({
        top: section.offsetTop - navbarHeight,
        behavior: 'smooth',
      });
    },
    onClickFirstPage() {
      this.$emit('page-set', 1);
    },
    onClickPreviousPage() {
      if (!this.isInFirstPage) {
        this.$emit('page-set', this.currentPage - 1);
      }
    },
    onClickPage(page) {
      this.$emit('page-set', page);
    },
    onClickNextPage() {
      if (!this.isInLastPage) {
        this.$emit('page-set', this.currentPage + 1);
      }
    },
    onClickLastPage() {
      this.$emit('page-set', this.amountOfPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>
