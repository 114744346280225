<template>
  <value-filter
    v-if="!isRange"
    :name="name"
    :filter="filter"
  />
  <range-filter
    v-if="isRange"
    :name="name"
    :filter="filter"
  />
</template>

<script>
import ValueFilter from './ValueFilter.vue';
import RangeFilter from './RangeFilter.vue';

export default {
  components: {
    ValueFilter,
    RangeFilter,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    isRange() {
      return !!this.filter.to && !!this.filter.from;
    },
  },
};
</script>
