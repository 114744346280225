<template>
  <div v-if="hasOptions">
    <fieldset class="facet">
      <slot :facet-name="name" :options="options">
        <FormSelect :label="name" :options="options[0].data" @option-changed="handleOptionChanged" />
      </slot>

      <div class="facet__actions">
        <slot name="actions" :fetch-all-options="fetchAllOptions" :fetched-all-options="fetchedAllOptions" />
      </div>
    </fieldset>
  </div>
</template>

<script>
import mitt from '../../lib/mitt';
import { FACET_FETCH_ALL_OPTIONS, SET_FILTER } from '../../constants';
import { useAppSearch } from '../../composables/useAppSearch';
import FormSelect from '../Select.vue';

export default {
  components: {
    FormSelect,
  },
  props: {
    name: String,
    options: Object,
  },
  setup() {
    const { clients } = useAppSearch();

    return {
      clients,
    };
  },
  data() {
    return {
      fetchedAllOptions: false,
    };
  },
  computed: {
    hasOptions() {
      return this.options[0].data.length;
    },
  },
  methods: {
    handleOptionChanged(payload) {
      const top4 = document.querySelector('.section--top-4');

      if (top4) {
        top4.parentElement.removeChild(top4);
      }

      mitt.emit(SET_FILTER, { facet: this.name, ...payload });
    },
    fetchAllOptions() {
      mitt.emit(FACET_FETCH_ALL_OPTIONS, this.name);
      this.fetchedAllOptions = true;
    },
  },
};
</script>
