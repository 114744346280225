<template>
  <app-search-ui :options="appSearchOptions">
    <template #facets="{ facets }">
      <filters
        :facets="facets"
        :facets-with-range-input="appSearchOptions.facetsWithRangeInput"
      />
    </template>

    <template #filters="{ filters, handleClearFilters }">
      <active-filters :filters="filters" @clear-filters="handleClearFilters" />
    </template>

    <template #sorter="{ page, results, handleSortChanged, amountOfResults }">
      <sorter
        :page="page"
        :results="results"
        :amount-of-results="amountOfResults"
        @sort-changed="handleSortChanged"
      />
    </template>

    <template #default="{ results }">
      <div class="results">
        <results :results="results">
          <template #default="{ result }">
            <result :result="result" />
          </template>
        </results>
      </div>
    </template>

    <template #pagination="{ page, handlePageSet }">
      <pagination :page="page" @page-set="handlePageSet" />
    </template>
  </app-search-ui>
</template>

<script>
import AppSearchUi from "./components/functional/AppSearchUi.vue";
import Results from "./components/functional/Results.vue";
import Result from "./components/Result.vue";
import Filters from "./components/Filters.vue";
import ActiveFilters from "./components/ActiveFilters.vue";
import Sorter from "./components/Sorter.vue";
import Pagination from "./components/functional/Pagination.vue";

export default {
  components: {
    AppSearchUi,
    Filters,
    ActiveFilters,
    Sorter,
    Results,
    Result,
    Pagination,
  },
  computed: {
    client() {
      return this.clients?.productsClient;
    },
    facetOptions() {
      return {
        categories: {
          type: "value",
        },
        filter_gender_type: {
          type: "value",
        },

        filter_brand: {
          type: "value",
        },

        filter_outlet: {
          type: "value",
        },

        filter_rush_route: {
          type: "value",
        },

        filter_gift_wrapping_possible: {
          type: "value",
        },

        filter_eco: {
          type: "value",
        },

        filter_new_product: {
          type: "value",
        },

        filter_print_technique: {
          type: "value",
        },

        filter_material: {
          type: "value",
        },

        filter_custom_sleeve_possible: {
          type: "value",
        },

        filter_color: {
          type: "value",
        },

        filter_writing_color: {
          type: "value",
        },

        minimum_price: {
          type: "value",
        },
      };
    },
    sizeOptions() {
      return {
        options: [25, 50, 100, 200],
        defaultValue: 100,
        facetDefaultSize: 200,
      };
    },
    appSearchOptions() {
      return {
        client: {
          searchKey: window.appSearchKey,
          endpointBase: window.appSearchBaseUrl,
          engineName: window.appSearchEngine,
        },
        facets: this.facetOptions,
        facetsWithRangeInput: [
          "minimum_price",
          "price_100_units",
          "minimum_discount_price",
        ],
        sizeOptions: this.sizeOptions,
      };
    },
  },
};
</script>
