<template>
  <slot v-for="key in facetKeys" :key="key" :value="facets[key]" name="facet" />
</template>

<script>
import dropdown from '../../../../forms/dropdown';

export default {
  props: {
    facets: {
      type: Object,
    },
  },
  computed: {
    facetKeys() {
      return Object.keys(this.facets);
    },
  },
  updated() {
    dropdown();
  },
  created() {
    this.$nextTick(() => {
      dropdown();
    });
  },
};
</script>
