<template>
  <button
    v-for="value in filter"
    :key="value"
    type="button"
    class="active-filter"
    @click="removeFilter(value)"
  >
    <span class="active-filter__key">{{ newKey }}:&nbsp;</span>
    <span class="active-filter__value">{{ value }}</span>
    <i class="icon-cross" />
  </button>
</template>

<script>
import mitt from '../lib/mitt';

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    newKey() {
      const formattedKey = this.name.replace('filter_', '');
      const data = window.facet_names;

      if (Object.prototype.hasOwnProperty.call(data, formattedKey) && data[formattedKey] !== '') {
        return data[formattedKey];
      }

      return formattedKey.replace(/_/gi, ' ');
    },
  },
  methods: {
    removeFilter(value) {
      mitt.emit('disable-filter-option', { facet: this.name, option: value });
    },
  },
};
</script>
