<template>
  <div>
    <fieldset class="facet">
      <slot :facet-name="name" :options="options">
        <Range
          :label="name"
          :options="options[0].data"
          :min-value="minValue"
          :max-value="maxValue"
          @range-changed="handleRangeChanged"
        />
      </slot>

      <div class="facet__actions">
        <slot name="actions" :fetch-all-options="fetchAllOptions" :fetched-all-options="fetchedAllOptions" />
      </div>
    </fieldset>
  </div>
</template>

<script>
import mitt from '../../lib/mitt';
import { FACET_FETCH_ALL_OPTIONS, SET_FILTER } from '../../constants';
import { useAppSearch } from '../../composables/useAppSearch';
import Range from '../Range.vue';

export default {
  components: {
    Range,
  },
  props: {
    name: String,
    options: Object,
  },
  setup() {
    const { clients } = useAppSearch();

    return {
      clients,
    };
  },
  data() {
    return {
      fetchedAllOptions: false,
      isOpen: false,
    };
  },
  computed: {
    hasOptions() {
      return this.options[0].data.length;
    },
    flatOptions() {
      return this.options[0].data.map((option) => option.value);
    },
    minValue() {
      return this.flatOptions.reduce((max, val) => (max < val ? max : val)).toFixed(2);
    },
    maxValue() {
      return this.flatOptions.reduce((max, val) => (max > val ? max : val)).toFixed(2);
    },
  },
  methods: {
    handleRangeChanged(payload) {
      const top4 = document.querySelector('.section--top-4');

      if (top4) {
        top4.parentElement.removeChild(top4);
      }

      mitt.emit(SET_FILTER, {
        facet: this.name,
        value: 'range',
        option: payload,
      });
    },
    fetchAllOptions() {
      mitt.emit(FACET_FETCH_ALL_OPTIONS, this.name);
      this.fetchedAllOptions = true;
    },
  },
};
</script>
