<template>
  <div v-if="hasResults" class="products-grid">
    <slot
      v-for="(result, index) in results"
      :key="index"
      :result="result"
    ></slot>
  </div>
</template>

<script>
export default {
  props: {
    results: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasResults() {
      return this.results?.length;
    },
  },
  mounted() {
    if (this.results?.length) {
      let items = [];
      let counter = 1;
      this.results.forEach((item) => {
        items.push({
          item_id: item.data.id.raw,
          item_name: item.data.name.raw,
          price: item.data.minimum_price.raw,
          quantity: item.data.minimum_order_quantity.raw,
          item_list_name: "results",
          index: counter,
        });
        counter++;
      });

      this.$gtm.trackEvent({
        event: "view_item_list",
        ecommerce: {
          items: items,
        },
      });
    }
  },
};
</script>
