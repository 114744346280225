<template>
  <div ref="formSelect" :class="wrapperClasses">
    <div class="form-select__inner">
      <div class="form-select__btn" tabindex="0">
        <span>{{ newLabel }}</span>
      </div>

      <div ref="list" :class="listClasses" aria-label="Select Wrapper">
        <div class="form-select__list__body">
          <div class="form-select__list__scroller">
            <form @submit="handleFormSubmit">
              <div class="form-select--range__inputs">
                <span>€</span>

                <input
                  v-model="min"
                  class="form-control"
                  type="number"
                  :placeholder="minValue.replace('.', ',')"
                  :min="(Math.ceil((minValue - 0.05) * 20) / 20).toFixed(2)"
                  :max="(Math.ceil(maxValue * 20) / 20).toFixed(2)"
                  step="0.05"
                  @change="handleChange"
                >

                <span>Tot</span>

                <input
                  v-model="max"
                  class="form-control"
                  type="number"
                  :placeholder="maxValue.replace('.', ',')"
                  :min="(Math.ceil(minValue * 20) / 20).toFixed(2)"
                  :max="(Math.ceil((maxValue - -0.05) * 20) / 20).toFixed(2)"
                  step="0.05"
                  @change="handleChange"
                >
              </div>

              <button type="submit" class="btn btn--primary">
                <span>Ga</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hide } from '../../../util/show-hide';

export default {
  components: {
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    minValue: {
      type: String || Number,
      required: false,
      default: 0,
    },
    maxValue: {
      type: String || Number,
      required: true,
    },
  },
  emits: ['range-changed'],
  data() {
    return {
      min: (Math.ceil((this.minValue - 0.05) * 20) / 20).toFixed(2),
      max: (Math.ceil((this.maxValue - -0.05) * 20) / 20).toFixed(2),
    };
  },
  computed: {
    isCollapsing() {
      return this.collapsing;
    },
    wrapperClasses() {
      return {
        'form-select': true,
        'form-select--custom': true,
        'form-select--range': true,
      };
    },
    listClasses() {
      return {
        collapse: true,
        'form-select__list': true,
      };
    },
    newLabel() {
      const formattedLabel = this.label.replace('filter_', '');
      const data = window.facet_names;

      if (typeof data !== 'undefined' && Object.prototype.hasOwnProperty.call(data, formattedLabel) && data[formattedLabel] !== '') {
        return data[formattedLabel];
      }

      return formattedLabel.replace(/_/gi, ' ');
    },
  },
  methods: {
    handleFormSubmit($event) {
      $event.preventDefault();

      const payload = {
        from: Number(this.min),
        to: Number(this.max),
      };
      this.$emit('range-changed', payload);
      hide(this.$refs.list);
      this.$refs.formSelect.classList.remove('active');
    },
    handleChange($event) {
      const value = parseFloat($event.target.value);

      if (Number.isNaN(value)) {
        $event.target.value = '0,00';
      } else {
        $event.target.value = value.toFixed(2);
      }
    },
  },
};
</script>
