<template>
  <div class="filters">
    <button
      type="button"
      class="btn btn--primary btn--il d-lg-none"
      @click="toggleFilters"
    >
      <i class="icon-filter"></i><span>Filteren</span>
      <i class="icon-cross"></i>
    </button>

    <div ref="filtersInner" class="filters__inner collapse">
      <slot
        name="sorter"
        :page="page"
        :results="results"
        :handle-sort-changed="handleSortChanged"
        :amount-of-results="amountOfResults"
      />

      <div class="filters__wrap">
        <!-- <size-select :size-options="sizeOptions" :selected-size="size" @size-selected="handleSizeSelected" /> -->
        <slot name="facets" :facets="facets" :handle-search="handleSearch" />
        <slot
          name="filters"
          :filters="filters"
          :handle-clear-filters="handleClearFilters"
        />
      </div>
    </div>
  </div>

  <slot v-if="amountOfResults > 0" :results="results" />

  <h3 v-else>Geen resultaten gevonden</h3>

  <slot
    v-if="amountOfResults > 0"
    name="pagination"
    :page="page"
    :handle-page-set="handlePageSet"
  />
</template>

<script>
import merge from "lodash.merge";
import { show, hide } from "../../../../util/show-hide";
import mitt from "../../lib/mitt";
import { useAppSearch } from "../../composables/useAppSearch";
import { FACET_FETCH_ALL_OPTIONS, SET_FILTER } from "../../constants";

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { createClient, clients } = useAppSearch();

    return {
      createClient,
      clients,
    };
  },
  data() {
    return {
      facets: {},
      page: {},
      results: null,
      current: 1,
      size: 25,
      sizeOptions: [16, 48, 96],
      isLoading: true,
      facetOptionsOverrides: {},
      filters: {},
      query: "",
    };
  },
  computed: {
    pageOptions() {
      return {
        current: Number(this.current),
        size: Number(this.size),
      };
    },
    mergedFacetOptions() {
      const {
        facets: facetOptions,
        sizeOptions: { facetDefaultSize },
      } = this.options;
      Object.keys(facetOptions).forEach((key) => {
        if (facetOptions[key].type === "value") {
          facetOptions[key].size = facetDefaultSize;
        }
      });
      return merge(facetOptions, this.facetOptionsOverrides);
    },
    formattedFilters() {
      const all = Object.keys(this.filters).map((key) => ({
        [key]: this.filters[key],
      }));

      // let minimumPrice = { minimum_price: this.minimumPrice };

      // if (this.minimumPrice) {
      //   minimumPrice = { minimum_price: { to: this.minimumPrice } };
      // }

      const minimumOrderQuantity = {
        minimum_order_quantity: this.minimumOrderQuantity,
      };
      const categoryId = { category_ids: this.categoryId };
      const visibility = { visibility: ["catalog", "catalog, search"] };
      const bestsellers = { bestseller: this.bestsellers };

      all.push(minimumOrderQuantity, categoryId, visibility, bestsellers);

      // all.push(minimumPrice, minimumOrderQuantity, categoryId);

      return {
        all,
      };
    },
    amountOfResults() {
      const { total_results: totalResults } = this.page;
      return totalResults;
    },
  },
  created() {
    let facets = new URLSearchParams(window.location.search).get("facets");
    let query = new URLSearchParams(window.location.search).get("query");

    if (!facets) {
      const querystring = document
        .querySelector("#appSearchUi")
        .getAttribute("data-querystring");

      if (querystring) {
        facets = new URLSearchParams(querystring).get("facets");
      }
    }

    if (facets) {
      this.filters = JSON.parse(decodeURIComponent(facets));
    }

    if (!query) {
      const querystring = document
        .querySelector("#appSearchUi")
        .getAttribute("data-querystring");

      if (querystring) {
        query = new URLSearchParams(querystring).get("query");
      }
    }

    if (query) {
      this.query = decodeURIComponent(query);
    }

    // Handle data attributes
    const categoryId = document
      .querySelector("#appSearchUi")
      .getAttribute("data-category-id");

    if (categoryId) {
      this.categoryId = categoryId;
    }

    const bestsellers = document
      .querySelector("#appSearchUi")
      .getAttribute("data-bestsellers");

    if (bestsellers) {
      this.bestsellers = bestsellers;
    }

    this.overrideDefaultSettings();
    this.initClient();

    mitt.on(FACET_FETCH_ALL_OPTIONS, this.handleFacetFetchAllOptions);
    mitt.on(SET_FILTER, this.handleSetFilter);
    mitt.on("remove-ranged-filter", this.handleRemoveRangedFilter);
  },
  methods: {
    getVariable(querystring, variable) {
      const vars = querystring.split("&");
      for (let i = 0; i < vars.length; i += 1) {
        const pair = vars[i].split("=");

        // Querystring is double encoded
        if (decodeURIComponent(decodeURIComponent(pair[0])) === variable) {
          return decodeURIComponent(decodeURIComponent(pair[1]));
        }
      }

      return null;
    },
    overrideDefaultSettings() {
      const {
        sizeOptions: { options: sizeOptions, defaultValue },
      } = this.options;
      this.size = defaultValue;
      this.sizeOptions = sizeOptions;
    },
    initClient() {
      this.createClient(this.options);
      this.setQuery();
      this.search();
    },
    setQuery() {
      const query = new URLSearchParams(
        document.location.search.substring(1)
      ).get("query");
      const minimumPrice = new URLSearchParams(
        document.location.search.substring(1)
      ).get("minimum_price");
      const minimumOrderQuantity = new URLSearchParams(
        document.location.search.substring(1)
      ).get("minimum_order_quantity");
      const categoryId = new URLSearchParams(
        document.location.search.substring(1)
      ).get("category_id");

      if (query) {
        this.query = query;
      }

      if (minimumPrice) {
        this.minimumPrice = Number(minimumPrice);
      }

      if (minimumOrderQuantity) {
        this.minimumOrderQuantity = Number(minimumOrderQuantity);
      }

      if (categoryId) {
        this.categoryId = categoryId;
      }
    },
    search() {
      const {
        client: { engineName },
      } = this.options;
      const { instance } = this.clients[`${engineName}Client`];

      this.isLoading = true;

      const payload = {
        facets: this.mergedFacetOptions,
        filters: this.formattedFilters,
        page: this.pageOptions,
      };

      if (this.sort !== null) {
        payload.sort = this.sort;
      }

      instance
        .search(this.query, payload)
        .then(
          ({
            results,
            info: {
              facets,
              meta: { page },
            },
          }) => {
            this.results = results;
            this.page = page;
            this.facets = facets;
          }
        )
        .catch((error) => {
          // TODO: Handle this neatly
          throw new Error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFacetFetchAllOptions(facetToFetch) {
      this.facetOptionsOverrides[facetToFetch] = {
        size: 250,
      };
      this.search();
    },
    handleSetFilter({ facet, option, value }) {
      if (value === true) {
        if (this.filters[facet]) {
          this.filters[facet].push(option);
        } else {
          this.filters[facet] = [option];
        }
        this.search();
      } else if (value === false) {
        if (this.filters[facet].length > 1) {
          this.filters[facet].splice(this.filters[facet].indexOf(option), 1);
        } else {
          delete this.filters[facet];
        }
        this.search();
      } else if (value === "range") {
        this.filters[facet] = option;
        this.search();
      }

      if (Object.keys(this.filters).length) {
        this.setParam(
          "facets",
          encodeURIComponent(JSON.stringify(this.filters))
        );
      } else {
        this.removeParam("facets");
      }
    },
    handleClearFilters() {
      this.filters = {};
      this.search();
    },
    handleSearch(query) {
      this.query = query;
      this.search();
    },
    handleSizeSelected(selectedSize) {
      this.size = selectedSize;
      this.search();
    },
    toggleFilters() {
      const { filtersInner } = this.$refs;

      if (filtersInner.classList.contains("show")) {
        hide(filtersInner);
        filtersInner.parentElement.classList.remove("open");
      } else {
        filtersInner.parentElement.classList.add("open");
        show(filtersInner);
      }
    },
    handlePageSet(page) {
      this.setParam("page", page);
      this.current = page;
      this.search();
    },
    handleSortChanged(value) {
      if (value) {
        this.sort = {};
        this.sort[value] = "asc";
      } else {
        this.sort = null;
      }
      this.search();
      this.handlePageSet(1);
    },
    setParam(key, value) {
      const url = new URL(window.location);
      url.searchParams.set(key, value);
      window.history.pushState({}, document.title, url);
    },
    removeParam(key) {
      const url = new URL(window.location);
      url.searchParams.delete(key);
      window.history.pushState({}, document.title, url);
    },
    handleRemoveRangedFilter({ facet }) {
      this.handleSetFilter({ facet, value: false });
    },
  },
};
</script>
