import { createApp } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";
import AppSearchUi from "./vue-apps/app-search-ui/App.vue";

const appSearchContainer = document.querySelector("#appSearchUi");

if (appSearchContainer) {
  const app = createApp(AppSearchUi);
  app.use(
    createGtm({
      id: "GTM-5B38NJ",
    })
  );

  app.mount(appSearchContainer);
}
