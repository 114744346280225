<template>
  <div class="form-check">
    <input :id="`${facet}-${index}`" ref="checkbox" type="checkbox" name="checkbox" @change="handleChange">

    <label :for="`${facet}-${index}`" tabindex="-1" role="option">
      <span class="form-check__indicator"></span>
      <span class="form-check__text">
        {{ label }} <small>({{ count }})</small>
      </span>
    </label>
  </div>
</template>

<script>
import mitt from '../lib/mitt';

export default {
  props: {
    index: {
      type: Number,
    },
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    facet: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ['option-changed'],
  data() {
    return {
      checked: false,
    };
  },
  computed: {
    isChecked() {
      return this.checked;
    },
  },
  created() {
    let facets = new URLSearchParams(window.location.search).get('facets');

    if (!facets) {
      const querystring = document.querySelector('#appSearchUi').getAttribute('data-querystring');

      if (querystring) {
        facets = new URLSearchParams(querystring).get('facets');
      }
    }

    if (facets) {
      const parsedFilters = JSON.parse(decodeURIComponent(facets));

      if (parsedFilters[this.facet]) {
        this.checked = true;
        this.$nextTick(() => {
          this.$refs.checkbox.checked = this.checked;
        });
      }
    }

    mitt.on('disable-filter-option', this.handleDisableFilterOption);
  },
  methods: {
    handleChange() {
      this.checked = !this.checked;
      this.$refs.checkbox.checked = this.checked;
      this.$emit('option-changed', { option: this.label, value: this.checked });
    },
    handleDisableFilterOption({ facet, option }) {
      if (facet === this.facet && option === this.label) {
        this.handleChange();
      }
    },
  },
};
</script>
