<template>
  <div :class="wrapperClasses">
    <div class="form-select__inner">
      <div class="form-select__btn" tabindex="0">
        <span>{{ newLabel }}</span>
      </div>

      <div ref="list" :class="listClasses" role="listbox" aria-label="Select Wrapper">
        <div class="form-select__list__body">
          <div class="form-select__list__scroller">
            <select-option v-for="(option, index) in options" :key="option.value" :index="index" :facet="label" :value="option.value" :count="option.count" :label="option.value" @option-changed="handleOptionChanged" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectOption from './SelectOption.vue';

export default {
  components: {
    SelectOption,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  emits: ['option-changed'],
  computed: {
    isCollapsing() {
      return this.collapsing;
    },
    wrapperClasses() {
      return {
        'form-select': true,
        'form-select--custom': true,
        'form-select--checkbox': true,
      };
    },
    listClasses() {
      return {
        collapse: true,
        'form-select__list': true,
      };
    },
    newLabel() {
      const formattedLabel = this.label.replace('filter_', '');
      const data = window.facet_names;

      if (typeof data !== 'undefined' && Object.prototype.hasOwnProperty.call(data, formattedLabel) && data[formattedLabel] !== '') {
        return data[formattedLabel];
      }

      return formattedLabel.replace(/_/gi, ' ');
    },
  },
  methods: {
    handleOptionChanged(payload) {
      this.$emit('option-changed', payload);
    },
  },
};
</script>
