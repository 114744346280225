<template>
  <div class="active-filters">
    <div class="active-filters__inner">
      <div v-for="key in filterKeys" :key="key" class="active-filters__filters">
        <active-filter
          :name="key"
          :filter="filters[key]"
        />
        <!-- <active-filter v-for="value in filters[key]" :key="value" :filter="{ key, value }" /> -->
      </div>
    </div>

    <button v-if="filterKeys.length" type="button" class="link" @click="clearFilters">
      <i class="icon-trash"></i> Alle filters wissen
    </button>
  </div>
</template>

<script>
import ActiveFilter from './ActiveFilter.vue';

export default {
  components: {
    ActiveFilter,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  emits: ['clear-filters'],
  computed: {
    filterKeys() {
      return Object.keys(this.filters);
    },
  },
  methods: {
    clearFilters() {
      this.$emit('clear-filters');
    },
  },
};
</script>
