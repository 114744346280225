<template>
  <b class="d-lg-none">Filters:</b>

  <div class="filters__grid">
    <facets v-if="facets" :facets="facets">
      <template #facet="{ key: name, value: options }">
        <facet v-if="facetsWithRangeInput.indexOf(name) === -1" :name="name" :options="options" />
        <ranged-facet v-else :name="name" :options="options" />
      </template>
    </facets>
  </div>
</template>

<script>
import Facets from './functional/Facets.vue';
import Facet from './functional/Facet.vue';
import RangedFacet from './functional/RangedFacet.vue';

export default {
  components: {
    Facets,
    Facet,
    RangedFacet,
  },
  props: {
    facets: {
      type: Object,
      required: true,
    },
    facetsWithRangeInput: {
      type: Array,
      required: false,
    },
  },
};
</script>
